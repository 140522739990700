import React from "react";
import Head from "next/head";
import { ExtendedNextPage } from "types/pages";

import HummingNav from "pages/components/HummingNav";
import styles from "./styles.module.scss";
import {
  MainHeadline,
  Features,
  HowItWorks,
  ToDoList,
  OneMembership,
  OurServices,
  UserReviews,
  Footer
} from "./components";
import HummingFooter from "../components/HummingFooter";
import TheDifference from "./components/TheDifference";

const HomePage: ExtendedNextPage = () => {
  return (
    <div className={styles.container}>
      <Head>
        <title>Humming Homes | End-to-end home management for homeowners</title>
        <meta
          name="description"
          content="Humming Homes provides a digital home manager that handles any home service for you - from plumbing repairs to replacing your HVAC system and much more. Starting at $199 / year"
        />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://humminghomes.com" />
      </Head>
      <MainHeadline />
      <Features />
      <ToDoList />
      <OneMembership />
      <OurServices />
      <HowItWorks />
      <TheDifference />
      <UserReviews />
      <Footer />
    </div>
  );
};

HomePage.getLayout = (page) => {
  return (
    <div>
      <HummingNav />
      {page}
      <HummingFooter />
    </div>
  );
};

export default HomePage;
